import React from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/notFound.scss"

const NotFound = () => {
    return (
        <div className="wrapper">
            <Nav currentPage={0}/>
            <div className="main">
                <div className="error__message">
                    Error 404
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default NotFound;