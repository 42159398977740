import React from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/samplePages.scss"
import delivery1 from "../photos/delivery_1.jpg";
import delivery2 from "../photos/delivery_2.jpg";

const Delivery = () => {
    return (
        <div className="wrapper">
            <Nav currentPage={4}/>
            <div className="main">
                <div className="main__header">Доставка</div>
                <div className="main__blockTextPhoto">
                    <div className="main__block__text">
                        Изначально в АВАНТИ занимались исключительно доставкой собственных цветов, но, развивая свою логистическую систему, мы стали предлагать услуги перевозок.
                    </div>
                    <img src={delivery1} alt="" className="main__block__photo"/>
                </div>

                <div className="main__header">Как заказать у нас доставку?</div>
                <div className="main__blockTextPhoto" style={{marginBottom: "100px"}}>
                    <div className="main__block__text">
                        1. Позвонить нам <br/>
                        2. ператору сообщить о грузе, который необходимо перевезти (вес, габариты, особые пожелания)<br/>
                        3. Согласовать дату, время и адрес приема и доставки<br/>
                        4. При наличии скидочной карты сообщить о ней<br/>
                        5. Ожидать нашу доставку
                    </div>
                    <img src={delivery2} alt="" className="main__block__photo"/>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Delivery;