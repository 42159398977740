import React from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/contacts.scss"

const Contacts = () => {
    return (
        <div className="wrapper">
            <Nav currentPage={5}/>
            <div className="main">

                <div className="main__header">Наши контакты</div>

                <div className="contact__block">
                    <div className="contact__block__3">
                        <div className="contact__block__3__el">
                            В случае возникновения вопросов обращайтесь к нам
                        </div>
                        <div className="contact__block__3__el">
                            Почта: <a style={{color: "#B68D6E"}} href="mailto:info@717avanti.ru">info@717avanti.ru</a>
                        </div>
                        <div className="contact__block__3__el">
                            Телефон: <a style={{color: "#B68D6E"}} href="tel:+74991303054">+7(499) 130-30-54</a>
                        </div>
                    </div>
                    <div className="contact__block__3">
                        <div className="contact__block__3__el">
                            Регистрационные данные
                        </div>
                        <div className="contact__block__3__el">
                            Наименование: ООО “АВАНТИ”
                        </div>
                        <div className="contact__block__3__el">
                            Адрес: 141700, Московская область, г. Долгопрудный, ул. Дирижабельная, д.6 к. 3
                        </div>
                    </div>
                </div>




                <div className="main__header">Ждем <span style={{color: "#B68D6E"}}>Вашего</span> обращения!</div>

            </div>
            <Footer />
        </div>
    );
};

export default Contacts;