import React, {useEffect} from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/home.scss"
import videoBack from "../photos/video.MP4"
import postureVideo from "../photos/posture.jpg"
import arrow from "../photos/arrow.svg"
import {Link, useLocation} from "react-router-dom";
import cards from "../photos/main_4.jpg"
import Slider from "../components/Slider";
import slider1 from "../photos/main_slider_1.jpg"
import slider2 from "../photos/main_slider_2.jpg"
import slider3 from "../photos/main_slider_3.jpg"

const Home = () => {
    const location = useLocation()

    useEffect(() => {
        const id = location.hash.slice(1, 2)
        if (id === "1" || id === "2" || id === "3" || id === "4"){
            setTimeout(() => {
                document.getElementById(id).scrollIntoView({behavior: "smooth"})
            }, 100)
        }
    }, [location]);

    return (
        <div className="wrapper">
            <Nav currentPage={1}/>
            <div className="main">
                <div className="mp__firstBlock">
                    <div className="mp__firstBlock__text">
                        <span className="mp__firstBlock__text__name">AVANTI</span> - Ваш помощник
                        по всем вопросам
                    </div>
                </div>

                <div className="mp__threeblocks" id="4">
                    <div className="mp__threeblocks__two">
                        <Link className="mp_thhh" to="/cleaning">
                            <div className="mp__threeblocks__two__el__1">Клининг</div>
                        </Link>
                        <Link className="mp_thhh" to="/delivery">
                            <div className="mp__threeblocks__two__el__2">Доставка</div>
                        </Link>
                    </div>
                    <div className="mp__threeblocks__one">
                        <Link className="mp_thhh1" to="/flowers">
                            <div className="mp__threeblocks__one__el">Цветы</div>
                        </Link>
                    </div>
                </div>

                <div className="mp__whoweare" id="1">
                    <div className="mp__whoweare__video__cont">
                        <video className="mp__whoweare__video" loop muted autoPlay playsInline
                               poster={postureVideo}>
                            <source src={videoBack} type="video/mp4"/>
                        </video>
                    </div>

                    <div className="mp__whoweare__header">
                        Кто мы???
                    </div>

                    <div className="mp__whoweare__content">
                        AVANTI - это дружная команда профессионалов, которая знает, что именно необходимо клиенту. Наша
                        оснавная деятельность - это продажа цветов.
                    </div>
                    <div className="mp__whoweare__content">
                        Мы продаем как букеты, так и оптом цветы. Также мы оказываем услуги в сфере доставки и клининга.
                    </div>

                    <div className="mp__whoweare__buy">
                        <Link className="mp__whoweare__buy__text" to="/contacts">
                            Заказать цветы
                            <span style={{marginLeft: "15px"}}><img src={arrow} alt=""/></span>
                        </Link>
                    </div>
                </div>

                <div className="mp__whywe" id="2">Почему мы?</div>

                <div className="mp__whywe__content">
                    <div className="mp__whywe__content__text_block">
                        <div className="mp__whywe__content__text">
                            Мы постарались собрать в компании AVANTI только людей, имеющих большой опыт работы. Наш
                            персонал настолько квалифицирован, что вы точно не почувствуете дискомфорт от взаимодействия
                            с ним.
                        </div>
                        <div className="mp__whywe__content__text" style={{color: "#B68D6E", marginTop: "20px"}}>
                            Не бойтесь доверять нам самые важные задания
                        </div>
                    </div>

                    <div className="mp__whywe__content__info">
                        <div className="mp__whywe__content__info__text">
                            ● Доставка малогабаритных грузов<br/>
                            ● Доставка групногабаритных грузов<br/>
                            ● Клининг помещений до 100 м2<br/>
                            ● Клининг помещений свыше 100м2
                        </div>
                    </div>

                </div>

                <div className="mp__whywe" id="3">Система лояльности</div>

                <div className="mp__loyl">
                    <div className="mp__whywe__content__text_block">
                        <div className="mp__whywe__content__text">
                            Для постоянных клиентов у нас имеется система лояльности. Постоянные клиенты могут получить
                            скидки от 10% до 30%.
                        </div>
                        <div className="mp__whywe__content__text" style={{marginTop: "20px"}}>
                            После первого заказа по вашим контактным данным копится информация о покупках. После
                            достижения определенной суммы вы получаете нашу карту.
                        </div>
                    </div>

                    <div className="mp__loyl__photo">
                        <img className="mp__loyl_size" src={cards} alt=""/>
                    </div>

                </div>

                <div className="mp__whywe">Примеры оформления</div>

                <Slider photos={[slider1, slider2, slider3]}/>

            </div>
            <Footer/>
        </div>
    );
};

export default Home;