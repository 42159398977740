import React from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/samplePages.scss"
import cleaning1 from "../photos/cleaning_1.jpg";
import cleaning2 from "../photos/cleaning_2.jpg";
import cleaning3 from "../photos/cleaning_3.jpg";

const Cleaning = () => {
    return (
        <div className="wrapper">
            <Nav currentPage={3}/>
            <div className="main">
                <div className="main__header">Клининг</div>
                <div className="main__blockTextPhoto">
                    <div className="main__block__text">
                        Переодически в АВАНТИ поступали просьбы уборки цветов после мероприятий. И мы собрали команду специаолистов, чтобы открыть новую сферу для нас.
                        <div style={{marginTop: "20px"}}>
                            Мы оказываем услуги клининга на площадях свыше 50 м2.
                        </div>
                    </div>
                    <img src={cleaning1} alt="" className="main__block__photo"/>
                </div>

                <div className="main__header">Подробнее о клининге</div>
                <div className="main__blockTextPhoto reversedmain__block">
                    <div className="main__block__text">
                        Мы всегда большое внимание уделяем <span style={{color: "#B68D6E"}}>качеству и безопасности</span> используемых нами средств при клининге. Также не мало важным для нас остается и возможность уложиться во временные рамки клиента. Ведь мы <span style={{color: "#B68D6E"}}>гарантируем точное окончание уборки</span> к назначенному времени.
                        <div style={{marginTop: "20px"}}>
                            Мы максимально осознаем свою <span style={{color: "#B68D6E"}}>ответственность перед клиентами</span>, чтобы они ни при каких обстоятельствах не были разочарованы тем, что доверились именно нам.
                        </div>
                    </div>
                    <img src={cleaning2} alt="" className="main__block__photo"/>
                </div>

                <div className="main__header">Как заказать у нас клининг?</div>
                <div className="main__blockTextPhoto" style={{marginBottom: "100px"}}>
                    <div className="main__block__text">
                        1. Позвонить нам<br/>
                        2. Оператору сообщить о требуемом виде уборки (площадь, вид загрязнения и особые пожелания)<br/>
                        3. Согласовать дату, время и адрес приезда специалистов<br/>
                        4. При наличии скидочной карты сообщить о ней<br/>
                        5. Ожидать нашу бригаду специалистов
                    </div>
                    <img src={cleaning3} alt="" className="main__block__photo"/>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Cleaning;