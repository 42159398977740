import React, {useState} from 'react';
import "../css/slider.scss"
import arrowRight from "../photos/arrowSlider1.svg"
import arrowLeft from "../photos/arrowSlider2.svg"

const Slider = ({photos}) => {

    const [currentSlide, setCurrentSlide] = useState(1)

    const changeSlide = (direction) => {
        setCurrentSlide((photos.length + currentSlide + direction) % photos.length)
    }

    return (
        <div className="slider__container">
            <img src={arrowRight} onClick={() => {
                changeSlide(-1)
            }} className="slider__arrowRight" alt=""/>
            <img className="slider__photo1" src={photos[(3 + currentSlide - 1) % 3]} alt=""/>
            <img className="slider__photo2" src={photos[currentSlide]} alt=""/>
            <img className="slider__photo3" src={photos[(currentSlide + 1) % 3]} alt=""/>
            <img src={arrowLeft} onClick={() => {
                changeSlide(1)
            }} className="slider__arrowLeft" alt=""/>
        </div>
    );
};

export default Slider;