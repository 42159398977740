import './css/App.scss';
import Home from "./pages/Home";
import Flowers from "./pages/Flowers";
import Delivery from "./pages/Delivery";
import Cleaning from "./pages/Cleaning";
import Contacts from "./pages/Contacts";
import {Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound";

function App() {
  return (
      <Routes>
          <Route index element={<Home/>}/>
          <Route path="flowers" element={<Flowers/>}/>
          <Route path="delivery" element={<Delivery/>}/>
          <Route path="cleaning" element={<Cleaning/>}/>
          <Route path="contacts" element={<Contacts/>}/>

          <Route path="*" element={<NotFound/>}/>
      </Routes>
  );
}

export default App;
