import React from 'react';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/samplePages.scss"
import flower1 from "../photos/flowers_1.jpg"
import flower2 from "../photos/flowers_2.jpg"
import flower3 from "../photos/flowers_3.jpg"

const Flowers = () => {
    return (
        <div className="wrapper">
            <Nav currentPage={2}/>
            <div className="main">
                <div className="main__header">Цветы</div>
                <div className="main__blockTextPhoto">
                    <div className="main__block__text">
                        АВАНТИ уже имеет не малый опыт в сфере торговли цветами. Наша деятельность разделяется на два направления.<br/>
                        Первое это - продажа букетов, а второе - продажа цветов оптом, для компаний или декорации цветами мероприятий.
                    </div>
                    <img src={flower1} alt="" className="main__block__photo"/>
                </div>

                <div className="main__header">Качество <span style={{color: "#B68D6E"}}>цветов</span> превыше всего</div>
                <div className="main__blockTextPhoto reversedmain__block">
                    <div className="main__block__text">
                        Мы всегда большое внимание уделяем <span style={{color: "#B68D6E"}}>качеству и свежести</span> наших цветов. Также не мало важным для нас остается и своевременная доставка нашего товара. Ведь мы <span style={{color: "#B68D6E"}}>гарантируем точную доставку</span> цветов к назначенному времени.
                        <div style={{marginTop: "20px"}}>
                            Мы максимально осознаем свою <span style={{color: "#B68D6E"}}>ответственность перед клиентами</span>, чтобы они ни при каких обстоятельствах не были разочарованы тем, что доверились именно нам.
                        </div>
                    </div>
                    <img src={flower2} alt="" className="main__block__photo"/>
                </div>

                <div className="main__header">Как заказать у нас цветы?</div>
                <div className="main__blockTextPhoto" style={{marginBottom: "100px"}}>
                    <div className="main__block__text">
                        1. Позвонить нам<br/>
                        2. Оператор сообщить о цветах, которые в наличии (если необходимо отправить фото цветов)<br/>
                        3. Согласовать заказ, дату, время и адрес<br/>
                        4. При наличии скидочной карты сообщить о ней<br/>
                        5. Ожидать заказ
                    </div>
                    <img src={flower3} alt="" className="main__block__photo"/>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Flowers;