import React from 'react';
import logo from "../photos/goldlogo.svg";
import {Link} from "react-router-dom";
import "../css/nav.scss"

const Nav = ({currentPage}) => {
    return (
        <div className="nav">
            <div className="nav__logo">
                <Link to='/'><img className="nav__logo__el" src={logo} alt=""/></Link>
            </div>
            <div className="nav__content">
                <Link to='/' className={currentPage === 1 ? "linkToPage underLine" : "linkToPage"}>Главная</Link>
                <Link to='/flowers' className={currentPage === 2 ? "linkToPage underLine" : "linkToPage"}>Цветы</Link>
                <Link to='/cleaning' className={currentPage === 3 ? "linkToPage underLine" : "linkToPage"}>Клининг</Link>
                <Link to='/delivery' className={currentPage === 4 ? "linkToPage underLine" : "linkToPage"}>Доставка</Link>
                <Link to='/contacts' className={currentPage === 5 ? "linkToPage underLine" : "linkToPage"}>Контакты</Link>
            </div>
            <div className="nav__call">
                <a className="nav__call_el" href="tel:+74991303054">Позвонить</a>
            </div>
        </div>
    );
};

export default Nav;