import React from 'react';
import {Link} from "react-router-dom";
import "../css/footer.scss"
import logo from "../photos/logosmall.svg"

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__links">
                <div className="footer__links__column">
                    <Link to='/' onClick={() => {if (window.location.pathname !== "/"){window.scrollTo(0, 0)}}} className="footer__linkToPage">Главная</Link>
                    <Link to='/#1' onClick={() => {if (window.location.pathname !== "/"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">О нас</Link>
                    <Link to='/#2' onClick={() => {if (window.location.pathname !== "/"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">Почему мы</Link>
                    <Link to='/#3' onClick={() => {if (window.location.pathname !== "/"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">Скидки</Link>
                </div>
                <div className="footer__links__column">
                    <Link to='/#4' onClick={() => {if (window.location.pathname !== "/"){window.scrollTo(0, 0)}}} className="footer__linkToPage">Услуги</Link>
                    <Link to='/flowers' onClick={() => {if (window.location.pathname !== "/flowers"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">Цветы</Link>
                    <Link to='/cleaning' onClick={() => {if (window.location.pathname !== "/cleaning"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">Клининг</Link>
                    <Link to='/delivery' onClick={() => {if (window.location.pathname !== "/delivery"){window.scrollTo(0, 0)}}} className="footer__linkToScroll">Доставка</Link>
                </div>
                <div className="footer__links__column">
                    <Link to='/contacts' onClick={() => {if (window.location.pathname !== "/contacts"){window.scrollTo(0, 0)}}} className="footer__linkToPage">Контакты</Link>
                </div>
            </div>
            <div className="footer__logo">
                <Link className="footer__logo__size" to="/"><img src={logo} alt="" className="footer__logo__size"/></Link>
            </div>
            <div className="footer__contacts">
                <a className="footer__contact__us" href="mailto:info@717avanti.ru">Почта: info@717avanti.ru</a>
                <a className="footer__contact__us" href="tel:+74991303054">Телефон: +7(499) 130-30-54</a>
            </div>
        </div>
    );
};

export default Footer;